:root {

}

#navbar .MuiFormControl-root label, #navbar .MuiFormControl-root input{
     color:white;
}

.historical-temp-changes-table.Mui-selected {
    background-color: var(--primary-light) !important;
}